<template>
  <DashboardTemplateCasual
    v-loading="loading"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    :title="$t('message.statisticsPeriodReport')"
  >
    <h1>{{ $t("message.statisticsPeriodReport") }}</h1>

    <div v-loading="loading">
      <el-row :gutter="10" class="pd-b-3" justify align="middle">
        <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card pending">
            <font-awesome-icon icon="fa-solid fa-gamepad" />
            <div>
              <span class="number">{{ totalTest | comma }}</span>
              <p class="title">{{ $t("statisticsPeriodReport.totalTest") }}</p>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card success">
            <font-awesome-icon icon="fa-solid fa-square-check" />
            <div>
              <span class="number">{{ totalTestSuccess | comma }}</span>
              <p class="title">
                {{ $t("statisticsPeriodReport.totalTestSuccess") }}
              </p>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="8" :md="12" :sm="12" :xs="24" class="pd-b-4">
          <el-card class="statistic-card danger">
            <font-awesome-icon icon="fa-solid fa-circle-xmark" />
            <div>
              <span class="number">{{ totalTestFail | comma }}</span>
              <p class="title">
                {{ $t("statisticsPeriodReport.totalTestFail") }}
              </p>
            </div>
          </el-card>
        </el-col>
        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pd-b-4">
          <el-card class="chart-card">
            <h3 class="title">
              {{ $t("statisticsPeriodReport.playPercentSeries") }}
            </h3>
            <div class="chart">
              <Pie :series="playPercentSeries" :fetchChart="!loading" />
            </div>
          </el-card>
        </el-col>
        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="pd-b-4">
          <el-card class="chart-card">
            <h3 class="title">
              {{ $t("statisticsPeriodReport.avgSD") }}
            </h3>
            <div class="chart">
              <BarLabelRotation
                :series="avgSdseries"
                :xAxis="avgSdXAxis"
                :fetchChart="!loading"
              />
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </DashboardTemplateCasual>
</template>

<script>
import DashboardTemplateCasual from "@/template/DashboardTemplateCasual";
import Pie from "@/components/echarts/Pie";
import BarLabelRotation from "@/components/echarts/BarLabelRotation";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplateCasual,
    Pie,
    BarLabelRotation,
  },
  data() {
    return {
      loading: true,
      avgSdseries: [],
      avgSdXAxis: [
        this.$t("statisticsPeriodReport.mobile"),
        this.$t("statisticsPeriodReport.tablet"),
        this.$t("statisticsPeriodReport.computer"),
      ],
      playPercentSeries: [],
      totalTest: 0,
      totalTestSuccess: 0,
      totalTestFail: 0,
      testOfDesktop: 0,
      testOfTablet: 0,
      testOfMobile: 0,
      labelOption: {
        show: true,
        position: "insideBottom",
        distance: 15,
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        formatter: "{c}  {name|{a}}",
        fontSize: 16,
        rich: {
          name: {},
        },
      },
    };
  },
  watch: {
    "$i18n.locale"() {
      this.$store.commit("SET_BREADCRUMBS", [
        { path: "/", name: "home", i18n: true },
        { path: null, name: this.$t("message.statisticsPeriodReport") },
      ]);

      this.loading = true;
      this.fetchTestStatistics();
    },
  },
  mounted() {
    this.$store.commit("SET_BREADCRUMBS", [
      { path: "/", name: "home", i18n: true },
      { path: null, name: this.$t("message.statisticsPeriodReport") },
    ]);

    this.fetchTestStatistics();
  },
  destroyed() {
    this.$store.commit("SET_BREADCRUMBS", []);
  },
  methods: {
    async fetchTestStatistics() {
      try {
        let res = await HTTP.get(`/log/test/periods/statistics`);

        if (res.data.success) {
          this.totalTest = 0;
          this.totalTestSuccess = 0;
          this.totalTestFail = 0;

          let testOfMobile = 0;
          let testOfTablet = 0;
          let testOfDesktop = 0;

          let totalSumMobile = 0;
          let totalSumTablet = 0;
          let totalSumDesktop = 0;

          let dataSumMobile = [];
          let dataSumTablet = [];
          let dataSumDesktop = [];

          res.data.result.map((item) => {
            this.totalTest++;

            let wordRecognitionSucceed = false;
            let success = true;
            item.steps.map((step) => {
              if (step.key === "test9th" || step.key === "test9en") {
                if (step.level) {
                  wordRecognitionSucceed = true;
                }
              } else if (!step.level) {
                success = false;
              }
            });

            if (!wordRecognitionSucceed || !success) {
              this.totalTestFail++;
            }

            if (item.status === 1) {
              this.totalTestSuccess++;
            }

            let sum =
              (new Date(item.endTime).getTime() -
                new Date(item.startTime).getTime()) /
              1000;

            if (item.device === "Mobile") {
              testOfMobile++;
              totalSumMobile += sum;
              dataSumMobile.push(sum);
            } else if (item.device === "Tablet") {
              testOfTablet++;
              totalSumTablet += sum;
              dataSumTablet.push(sum);
            } else {
              testOfDesktop++;
              totalSumDesktop += sum;
              dataSumDesktop.push(sum);
            }
          });

          this.playPercentSeries = [
            {
              name: "Access From",
              type: "pie",
              radius: "90%",
              data: [
                {
                  value: testOfMobile,
                  name: this.$t("statisticsPeriodReport.mobile"),
                },
                {
                  value: testOfTablet,
                  name: this.$t("statisticsPeriodReport.tablet"),
                },
                {
                  value: testOfDesktop,
                  name: this.$t("statisticsPeriodReport.computer"),
                },
              ],
            },
          ];

          let avgMobile = testOfMobile > 0 ? totalSumMobile / testOfMobile : 0;
          let avgTablet = testOfTablet > 0 ? totalSumTablet / testOfTablet : 0;
          let avgDesktop =
            testOfDesktop > 0 ? totalSumDesktop / testOfDesktop : 0;

          let sdMobile =
            testOfMobile < 2
              ? 0
              : this.callSD(dataSumMobile, testOfMobile, avgMobile);
          let sdTablet =
            testOfTablet < 2
              ? 0
              : this.callSD(dataSumTablet, testOfTablet, avgTablet);
          let sdDesktop =
            testOfDesktop < 2
              ? 0
              : this.callSD(dataSumDesktop, testOfDesktop, avgDesktop);

          this.avgSdseries = [
            {
              name: this.$t("statisticsPeriodReport.avg"),
              type: "bar",
              barGap: 0,
              label: this.labelOption,
              emphasis: {
                focus: "series",
              },
              data: [
                avgMobile.toFixed(2),
                avgTablet.toFixed(2),
                avgDesktop.toFixed(2),
              ],
            },
            {
              name: this.$t("statisticsPeriodReport.sd"),
              type: "bar",
              label: this.labelOption,
              emphasis: {
                focus: "series",
              },
              data: [
                sdMobile.toFixed(2),
                sdTablet.toFixed(2),
                sdDesktop.toFixed(2),
              ],
            },
          ];
        }
      } catch (e) {
        const error = e.response;
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    callSD(datas, total, avg) {
      let totalPowDifference = 0;
      datas.map((item) => {
        let difference = item - avg;

        let powDifference = difference * difference;
        totalPowDifference += powDifference;
      });

      let sd = totalPowDifference / (total - 1);

      let radicalsNthRoot = Math.pow(sd, 1 / 2);

      return Math.floor(radicalsNthRoot * 100) / 100;
    },
  },
};
</script>

