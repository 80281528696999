<template>
  <div :class="className" :style="{ height: height, width: width }"></div>
</template>
<script>
import * as echarts from "echarts";
require("echarts/theme/macarons"); // echarts theme

export default {
  props: {
    parrentTaskValue: {
      type: Array,
      default: () => [],
    },
    parrentTask: {
      type: Array,
      default: () => [],
    },
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "500px",
    },
    pageType: {
      type: String,
      default: "",
    },
    series: {
      type: Array,
      default: () => [],
    },
    xAxis: {
      type: Array,
      default: () => [],
    },
    fetchChart: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    parrentTask() {
      this.initChart();
    },

    parrentTaskValue() {
      this.initChart();
    },

    fetchChart() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
      setTimeout(() => {
        this.initChart();
      }, 400);
    },
  },
  data() {
    return {
      chart: null,
      checkChart: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, "macarons");
      this.chart.setOption({
        textStyle: {
          fontFamily: "Rubik",
          color: "#000",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: this.xAxis,
            axisLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 1)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "rgba(0, 0, 0, 1)",
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0, 0, 0, 1)",
              },
            },
          },
        ],
        series: this.series,
      });
    },
  },
};
</script>
